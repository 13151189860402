import { useEffect, useState, memo } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Row, Col, Card, Image, Alert } from "react-bootstrap";
import { Link } from "react-router-dom";
import Meta from '../components/Meta';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStore, faCoins } from '@fortawesome/free-solid-svg-icons';
import { useAccount } from 'wagmi';
import { formatEther } from 'viem';
import abi from '../contracts/MarmottoShow-ABI.json';
import { ConnectButton } from '@rainbow-me/rainbowkit';
import { useReadContracts, useSimulateContract, useWriteContract, useWaitForTransactionReceipt } from 'wagmi';
import { sepolia } from 'viem/chains';

import toast from 'react-hot-toast';
import SleepingMarmot from "../assets/marmottoshow/sleeping-marmot.jpg";
import PaintingMarmot from "../assets/marmottoshow/painting-marmot.jpg";

const marmottoshowSmartContract = {
    address: process.env.REACT_APP_MARMOTTOSHOW_CONTRACT_ADDRESS,
    abi: abi,
    chainId: sepolia.id
};

const formatNumber = (num) => {
    let formatted = Number(num).toFixed(6);
    if (formatted.endsWith('0')) {
        formatted = formatted.slice(0, -1);
    }
    return formatted.endsWith('.00') ? formatted.slice(0, -3) : formatted;
};

function ipfsConvert(url) {
    const prefix = "ipfs://";
    if (url.startsWith(prefix)) {
        const cid = url.slice(prefix.length);
        return `https://${cid}.ipfs.dweb.link`;
    } else {
        return url;
    }
}

const Marmottoshow = () => {
    const pageTitle = 'Marmottoshis - MarmottoShow';
    const { tokenId } = useParams();
    const [tokensList, setTokensList] = useState([]);
    const { address, isConnected } = useAccount()
    const { chain } = useAccount()
    const [userSignature, setUserSignature] = useState('0x0000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000');

    const [justMinted, setJustMinted] = useState(false);

    useEffect(() => {
        return () => { setJustMinted(false); };
    }, [tokenId]); // Reset on tokenId change

    const allowlistServer = "https://wl.marmottoshis.app/signature";

    // Fetch all tokens from SC. useReadContracts used JIC
    const { data: multipleOnChainReads } = useReadContracts({
        contracts: [
            {
                ...marmottoshowSmartContract,
                functionName: 'tokensList'
            }
        ]
    });

    // Set data
    useEffect(() => {
        if (multipleOnChainReads) {
            setTokensList(multipleOnChainReads[0].result);
        }
    }, [multipleOnChainReads]);

    // Fetch signature from allowlist server
    useEffect(() => {
        if (address && tokenId) {
            async function fetchDetails(address) {
                if (!address) return;

                try {
                    const response = await fetch(`${allowlistServer}?address=${address}&id=${tokenId}`);
                    const details = await response.json();

                    if (details.signature) { setUserSignature(details.signature); }

                } catch (err) { console.log(err); }
            }

            fetchDetails(address);
        }
    }, [address, tokenId]);


    // Mint preparation
    const {
        data: mintData,
        error: mintPrepareError,
        isError: mintIsPrepareError,
        isSuccess: mintIsPrepareSuccess,
        refetch: mintRefetch,
    } = useSimulateContract({
        address: process.env.REACT_APP_MARMOTTOSHOW_CONTRACT_ADDRESS,
        abi: abi,
        chainId: sepolia.id,
        functionName: 'mintToken',
        args: [tokenId, userSignature],
        value: tokensList[tokenId] && tokensList[tokenId].price,
        query: { enabled: Boolean(address && tokenId), retry: false }
    });

    const { data: hash, writeContract: mintWrite, error } = useWriteContract();
    const { isLoading: mintIsLoading, isSuccess: mintIsSuccess } = useWaitForTransactionReceipt({ hash });

    // Mint done
    useEffect(() => {
        if (mintIsSuccess) {
            setJustMinted(true);
            toast.success(() => (<div><small>Mint réussi !</small></div>), { style: { wordBreak: 'break-word' }, duration: 6000 });
        }
    }, [mintIsSuccess])

    // Friendly error messages
    const friendlyErrorMessage = (errorMsg) => {
        const errorMappings = {
            "MarmottoShow: Address is not whitelisted for this token": "Désolé, le wallet actuellement connecté n'est pas whitelisté pour ce mint",
            "MarmottoShow: Address has already minted this token": "Vous avez déjà minté ce token avec le wallet actuellement connecté",
            "MarmottoShow: Token is not open for minting": "Le mint n'est pas actif pour ce token"
        };

        const errorMessage = errorMappings[errorMsg];

        if (typeof errorMessage === 'function') { return errorMessage(); }
        return errorMessage || 'Il semblerait que vous soyez à court de MATIC dans votre wallet... N\'hésitez pas à consulter la page Swap pour bridger vers Polygon.'; // Message d'erreur par défaut
    };

    // Single token render
    const SingleToken = memo(({ token }) => (
        <Col sm={6} md={token.isOpen == true ? 3 : 2}>
            <Card className="my-2">
                <Card.Img variant="top" src={ipfsConvert(token.image)} style={{ filter: token.isOpen ? '' : 'grayscale(100%)' }} />
                <Card.Body>
                    {token.isOpen == true && (<Card.Title>{token.name}</Card.Title>)}
                    {token.isOpen ? (
                        <Link to={`/marmottoshow/${token.id}`} className="btn btn-dark">Accéder au mint</Link>
                    ) : (
                        <a href={`${process.env.REACT_APP_MARMOTTOSHOW_MP_LINK}:${process.env.REACT_APP_MARMOTTOSHOW_CONTRACT_ADDRESS}:${token.id}`} target="_blank" rel="noreferrer" className='btn btn-dark btn-sm' title="Voir sur la marketplace">
                            <FontAwesomeIcon icon={faStore} className="flex-shrink-0 me-3" color="brown" size="lg" />
                            Marketplace
                        </a>
                    )}
                </Card.Body>
                {token.isOpen && (<Card.Footer>{formatEther(token.price) > 0 ? (`${formatNumber(formatEther(token.price))} ETH`) : 'Free Mint'}</Card.Footer>)}
            </Card>
        </Col>
    ));

    // Tokens display
    const tokensDisplay = () => {
        const filteredTokens = tokensList.filter(token => token.isOpen === true);
        const sortedTokens = filteredTokens.sort((a, b) => Number(b.id) - Number(a.id));
        return sortedTokens.length > 0 ? (
            <Col className="relief p-2">
                <h2>C'est tout show</h2>
                <Row className="nfts-cards justify-content-center">
                    {sortedTokens.map(token => (
                        <SingleToken key={Number(token.id)} token={token} />
                    ))}
                </Row>
            </Col>
        ) : (<Col lg={4} className="relief p-2">
            <Row className="justify-content-center">
                <Col sm={12} md={6} lg={10}>
                    <Card className="my-2">
                        <Card.Img variant="top" src={SleepingMarmot} />
                        <Card.Footer>Aucun mint pour le moment</Card.Footer>
                    </Card>
                </Col>
            </Row>
        </Col>);
    };

    return (
        <>
            <Meta title={pageTitle} />
            <Container className="mt-4">
                <Row className="mb-4">
                    <Col lg={12}>
                        <h1 className="text-center my-4 page-title">Marmotto Show</h1>
                        <div className="connect-zone my-2"><ConnectButton chainStatus="icon" label="Connecter son wallet" /></div>
                    </Col>
                </Row>

                {isConnected && (<>

                    {chain && chain.id != sepolia.id ? (<p className="text-center mb-5">- Le show se passe sur Polygon, veuillez changer de blockchain -</p>) : (<>

                        {tokenId && tokensList[tokenId] ? (<>
                            <Row className="justify-content-md-center p-2">
                                <Col lg={6} className="relief mb-3 p-2">
                                    <h2>{tokensList[tokenId].name}</h2>
                                    <Image src={ipfsConvert(tokensList[tokenId].image)} rounded className='img-fluid' />

                                    <p><small><a href={tokensList[tokenId].artistLink} target="_blank">By {tokensList[tokenId].artistName}</a></small></p>

                                    <p className='mt-4'>
                                        <button
                                            className={`btn ${justMinted ? 'btn-light' : (mintIsPrepareError ? 'btn-danger' : 'btn-light')}`}
                                            disabled={mintIsLoading || !mintIsPrepareSuccess || justMinted}
                                            onClick={() => {
                                                if (!justMinted) {
                                                    mintWrite(mintData?.request);
                                                }
                                            }}>
                                            <FontAwesomeIcon icon={faCoins} className="px-2" />
                                            {!mintIsLoading ? (
                                                justMinted ? 'Mint effectué' :
                                                    (formatEther(tokensList[tokenId]?.price || 0) > 0 ? `Mint (${formatNumber(formatEther(tokensList[tokenId]?.price))} ETH)` : 'Free Mint')
                                            ) : 'Mint en cours...'}
                                        </button>
                                    </p>

                                    {mintIsPrepareError && mintPrepareError.cause && !justMinted && (<>
                                        <div className="single-item-content mt-4 fs-6">
                                            <Alert variant='light'><span className="text-danger">{friendlyErrorMessage(mintPrepareError.cause.reason)}</span></Alert>
                                        </div></>)}

                                </Col>
                            </Row>

                            <Row>
                                <p className="text-center"><Link to={`/marmottoshow`}>↩ Retour à la liste des mints</Link></p>
                            </Row>

                        </>) : (<>
                            <Row className="justify-content-md-center p-2">
                                {tokensDisplay()}
                            </Row>
                            <Row className="justify-content-md-center p-2">
                                <Col lg={4} className="relief mb-5 p-2">
                                    <Row className="justify-content-center">
                                        <Col sm={12} md={6} lg={10}>
                                            <Card className="my-2">
                                                <Card.Header><small><a href={`#`} target="_blank" rel="noreferrer" className="text-black">
                                                    <FontAwesomeIcon icon={faStore} className="flex-shrink-0 me-3" color="brown" size="lg" />Accéder à la Marketplace</a></small>
                                                </Card.Header>
                                                <Card.Img variant="bottom" src={PaintingMarmot} />
                                            </Card>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </>
                        )}

                    </>)}
                </>)}
            </Container>
        </>
    );
};

export default Marmottoshow;
