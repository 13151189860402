import { useState, useEffect } from 'react';
import { Container, Row, Col, Card } from "react-bootstrap";

import { Link } from "react-router-dom";

import Meta from '../components/Meta'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare, faCoins, faGauge, faFire, faBookOpen, faPeopleGroup, faStore, faBaby } from '@fortawesome/free-solid-svg-icons'

import { useReadContract } from 'wagmi'

import Marmot from "../assets/homepage-marmot.png";
import UnrevealedNFT from "../assets/unrevealed-nft.jpg";
import MarketPlaceMarmot from "../assets/homepage-marketplace-marmot.jpg";
import BurningMarmot from "../assets/homepage-burning-marmot.jpg";
import AdoptMarmot from "../assets/homepage-adopt-marmot.jpg";

import { MouseParallaxContainer, MouseParallaxChild } from "react-parallax-mouse";

import abi from '../contracts/ABI.json';

const contractAddress = process.env.REACT_APP_CONTRACT_ADDRESS;

const Home = () => {

  const pageTitle = 'Marmottoshis : NFTs backés par du BTC'

  const [totalSupply, setTotalSupply] = useState(777);

  const { data: onChainTotalSupply } = useReadContract({
    address: contractAddress,
    abi: abi,
    functionName: 'totalSupply',
    chainId: 1,
  });

  useEffect(() => {
    if (onChainTotalSupply) {
      setTotalSupply(parseInt(onChainTotalSupply));
    }

  }, [onChainTotalSupply]);

  const currentPhase = parseInt(process.env.REACT_APP_CURRENT_PHASE);

  return (
    <>
      <Meta title={pageTitle} />


      <Container fluid>


        <Row>
          <Col xxl={7} className="p-sm-5 mx-auto mt-4">
            <Row>
              <Col lg={3} className="text-center">
                <MouseParallaxContainer>
                  <MouseParallaxChild factorX={0.05} factorY={0.05}>
                    <img src={Marmot} className="homepage-marmot" />
                  </MouseParallaxChild>
                </MouseParallaxContainer>
              </Col>
              <Col lg={9} className="p-4">
                <h1 className="total_nfts page-title">{totalSupply?.toString()} NFTs</h1>
                <p className="lead">Initialement 777 NFTs (21 modèles en 37 exemplaires), backés par du BTC accumulé chaque mois en DCA. Supply qui se réduit à chaque burn !</p>
                <p className="text-center">
                  {currentPhase === 0 ? (<Link to="/reservation" className="btn btn-dark btn-lg mb-2"><FontAwesomeIcon icon={faPenToSquare} className="flex-shrink-0 me-3" color="brown" size="lg" />Réservation</Link>) : null}
                  {currentPhase === 2 ? (<><Link to="/dashboard" className="btn btn-dark btn-lg mb-2"><FontAwesomeIcon icon={faGauge} className="flex-shrink-0 me-3" color="brown" size="lg" />Dashboard</Link></>) : null}
                </p>

                <Row className="nfts-cards justify-content-center">
                  {currentPhase === 1 ? (
                    <Col md={4}>
                      <Card className="mb-4">
                        <Card.Img variant="top" src={UnrevealedNFT} />
                        <Card.Body>
                          <Link to="/mint" className="btn btn-dark btn"><FontAwesomeIcon icon={faCoins} className="flex-shrink-0 me-3" color="brown" />Mint</Link>
                        </Card.Body>
                      </Card>
                    </Col>) : null}

                  {currentPhase === 2 ? (
                    <Col md={4}>
                      <Card className="mb-4">
                        <Card.Img variant="top" src={BurningMarmot} />
                        <Card.Body>
                          <Link to="/burn" className="btn btn-dark btn"><FontAwesomeIcon icon={faFire} className="flex-shrink-0 me-3" color="brown" />Burn</Link>
                        </Card.Body>
                      </Card>
                    </Col>) : null}

                  <Col md={4}>
                    <Card className="mb-4">
                      <Card.Img variant="top" src={AdoptMarmot} />
                      <Card.Body>
                        <Link to="/adopt" className="btn btn-dark btn"><FontAwesomeIcon icon={faBaby} className="flex-shrink-0 me-3" color="brown" />Adoption</Link>
                      </Card.Body>
                    </Card>
                  </Col>

                  <Col md={4}>
                    <Card className="mb-4">
                      <Card.Img variant="top" src={MarketPlaceMarmot} />
                      <Card.Body>
                        <a href="https://marmottoshis.wlbl.xyz" target="_blank" rel="noreferrer" className='btn btn-dark btn' title="Accéder à la marketplace"><FontAwesomeIcon icon={faStore} className="flex-shrink-0 me-3" color="brown" />Marketplace</a>
                      </Card.Body>
                    </Card>
                  </Col>

                </Row>

              </Col>
            </Row>
          </Col>
        </Row>

      </Container>

      <div className="divider"></div>
      <br />

      <Container>

        <Row className="my-5">
          <Col md={{ span: 2, offset: 1 }} className="text-center mb-4">
            <div>
              <h4 className="fw-bold mb-3"><FontAwesomeIcon icon={faBookOpen} color="brown" className="me-2" size="lg" fixedWidth />Lite Paper</h4>
              <p>Découvrez le projet dans ses moindres détails.</p>
            </div>
          </Col>
          <Col md={2} className="text-center mb-4">
            <div>
              <h4 className="fw-bold mb-3"><FontAwesomeIcon icon={faGauge} color="brown" className="me-2" size="lg" fixedWidth />Dashboard</h4>
              <p>Disposez d'une vision d'ensemble sur le projet.</p>
            </div>
          </Col>
          <Col md={2} className="text-center mb-4">
            <div>
              <h4 className="fw-bold mb-3"><FontAwesomeIcon icon={faFire} color="brown" className="me-2" size="lg" fixedWidth fade />Burn</h4>
              <p>Transformez vos NFTs en satoshis.</p>
            </div>
          </Col>
          <Col md={2} className="text-center mb-4">
            <div>
              <h4 className="fw-bold mb-3"><FontAwesomeIcon icon={faBaby} color="brown" className="me-2" size="lg" fixedWidth />Adopt</h4>
              <p>Adoptez une marmotte.</p>
            </div>
          </Col>
          <Col md={2} className="text-center mb-4">
            <div>
              <h4 className="fw-bold mb-3"><FontAwesomeIcon icon={faPeopleGroup} color="brown" className="me-2" size="lg" fixedWidth />Team</h4>
              <p>Découvrez l'équipe derrière le projet.</p>
            </div>
          </Col>
        </Row>

      </Container>


    </>
  )
}

export default Home