import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Row, Col, Card } from 'react-bootstrap';
import axios from 'axios';
import Meta from '../components/Meta';
import { usePublicClient } from 'wagmi';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStore } from '@fortawesome/free-solid-svg-icons';
import abi from '../contracts/ABI.json';

const contractAddress = process.env.REACT_APP_CONTRACT_ADDRESS;
const nft_families = Array.from({ length: 21 }, (_, i) => i + 1);

const Marmot = () => {
    const pageTitle = 'Marmottoshis - Marmot';
    const { id } = useParams();
    const publicClient = usePublicClient({ chainId: 1 });

    const SingleNFT = ({ nft_family_id }) => {
        const [nftIPFSDatas, setNftIPFSDatas] = useState({});
        const [nftSupply, setNftSupply] = useState(null);

        useEffect(() => {
            const fetchData = async () => {
                const supply = await publicClient.readContract({
                    address: contractAddress,
                    abi: abi,
                    functionName: 'supplyByID',
                    args: [nft_family_id]
                });
                setNftSupply(supply);

                const uriResponse = await publicClient.readContract({
                    address: contractAddress,
                    abi: abi,
                    functionName: 'uri',
                    args: [nft_family_id],
                });

                if (uriResponse) {
                    const nftURI = uriResponse.replace('ipfs://', 'https://gateway.ipfs.io/ipfs/');
                    const response = await axios.get(nftURI);
                    setNftIPFSDatas({
                        name: response.data.name,
                        satoshis: response.data.attributes[0].value,
                        description: response.data.description
                    });
                }
            };

            fetchData();
        }, [nft_family_id, publicClient]);

        return (
            <Col sm={4}>
                <Card className="mb-4">
                    <Card.Img variant="top" src={`/images/nfts/${nft_family_id}.jpg`} />
                    <Card.Body>
                        <Card.Title>{nftIPFSDatas.name ? nftIPFSDatas.name : `Marmot #${nft_family_id}`}</Card.Title>
                        {nftIPFSDatas.satoshis >= 1 ? (
                            <a href={`https://opensea.io/assets/ethereum/${contractAddress}/${nft_family_id}`} target="_blank" rel="noreferrer" className='btn btn-dark btn-lg' title="Voir sur OpenSea">
                                <FontAwesomeIcon icon={faStore} className="flex-shrink-0 me-3" color="brown" size="lg" />OpenSea
                            </a>
                        ) : null}
                    </Card.Body>
                    <Card.Footer>
                        {nftSupply == null ? ('Chargement en cours') : (
                            `${nftSupply} ${nftSupply > 1 ? 'exemplaires' : 'exemplaire'} en circulation`
                        )}
                        {nftIPFSDatas.satoshis >= 1 && (
                            <>
                                <br />{nftIPFSDatas.satoshis} Satoshis adossés<br /><hr /> {nftIPFSDatas.description}
                            </>
                        )}
                    </Card.Footer>
                </Card>
            </Col>
        );
    };

    const isValidMarmot = (id) => {
        return id.trim() !== '' && nft_families.includes(parseInt(id));
    };

    const marmotUI = () => (
        <>
            {isValidMarmot(id) ? (
                <div className="m-2">
                    <Row className="nft-card">
                        <SingleNFT key={id} nft_family_id={parseInt(id)} />
                    </Row>
                </div>
            ) : (
                <h1 className="text-center my-4 page-title">Marmotte introuvable</h1>
            )}
        </>
    );

    return (
        <>
            <Meta title={pageTitle} />
            <Container>
                <Row>
                    <Col sm={12} className="mt-4">{marmotUI()}</Col>
                </Row>
            </Container>
        </>
    );
};

export default Marmot;