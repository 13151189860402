import { Container, Row, Col, Accordion } from "react-bootstrap";

import Meta from '../components/Meta'

import Trocador1 from "../assets/trocador/trocador-1.jpg";
import Trocador2 from "../assets/trocador/trocador-2.jpg";
import Trocador3 from "../assets/trocador/trocador-3.jpg";
import Trocador4 from "../assets/trocador/trocador-4.jpg";
import Trocador5 from "../assets/trocador/trocador-5.jpg";

const Trocador = () => {

  const pageTitle = 'Marmottoshis - Trocador'

  function Tuto() {
    return (
      <Accordion className="trocador-tuto mb-4">
        <Accordion.Item eventKey="0">
          <Accordion.Header>Étape 1/4</Accordion.Header>
          <Accordion.Body>
            <p>Pour effectuer un échange, indiquez la crypto que vous souhaitez envoyer et son montant puis la crypto que vous souhaitez recevoir en échange (attention à la blockchain sélectionnée en envoi et en réception).</p>
            <img src={Trocador1} className="my-4" alt="Trocador - Étape 1/4" />
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
        <Accordion.Header>Étape 2/4</Accordion.Header>
          <Accordion.Body>
            <p>Trocador va ensuite vous proposer plusieurs taux de change (du plus avantageux au moins avantageux).</p>
            <img src={Trocador2} className="my-4" alt="Trocador - Étape 2/4" />
            <p>Chaque exchange obtient une notation cr&eacute;&eacute;e par Trocador allant de A &agrave; D ainsi que des informations concernant l&rsquo;enregistrement d&rsquo;information li&eacute; &agrave; l&rsquo;appareil utilis&eacute; (adresse IP, etc&hellip;), en dernier le bouclier indique &agrave; quelle hauteur votre &eacute;change est assur&eacute; par Trocador (bouclier 100% blanc = 100% assur&eacute;, dans le cas contraire passer la souris dessus pour voir le % du montant assur&eacute;).</p>
            <img src={Trocador3} className="my-4" alt="Trocador - Étape 2/4" />
            <p><b>MarmottAdvice</b><br /><small>Si la privacy vous importe privil&eacute;giez les exchanges ayant une notation A, B voire C accompagn&eacute;s id&eacute;alement du logo &laquo; &OElig;il vert &raquo;</small></p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
        <Accordion.Header>Étape 3/4</Accordion.Header>
          <Accordion.Body>
            <p>Une fois l’exchange choisi, indiquez une adresse de réception compatible (évitez l’utilisation d’adresse de CEX (Binance, KuCoin…) pour l’envoi & la réception afin d’éviter tout blocage).</p>
            <p>Pensez à mettre une adresse de remboursement en cas d’utilisation en envoi d’une privacy blockchain (XMR...)</p>
            <img src={Trocador4} className="my-4" alt="Trocador - Étape 3/4" />
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3">
        <Accordion.Header>Étape 4/4</Accordion.Header>
          <Accordion.Body>
            <p>Cela va ensuite vous générer une adresse sur laquelle envoyer la crypto que vous souhaitez échanger. Envoyez bien le montant que vous avez indiqué.</p>
            <p>Une fois les cryptos reçues par l’exchange, vous recevrez la crypto demandée sur l’adresse de réception que vous avez indiquée.</p>
            <img src={Trocador5} className="my-4 i-1000" alt="Trocador - Étape 4/4" />
            <p><b>MarmottAdvice</b><br /><small>Conservez les informations d’échanges (références…) tant que vous n’avez pas reçu, sur l’adresse de réception transmise à Trocador, la crypto prévue lors de l’échange.<br />Elles vous serviront, en cas de soucis, à communiquer avec le support de Trocador (très réactif).<br />Ne paniquez pas si la réception vous paraît longue, le délai de transaction variant d’un exchange à l’autre.<br />N’hésitez pas à patienter une heure sans évolution de suivi avant de contacter leur support.</small></p>
            <p><i>⚠️ Pour rappel, les Marmottoshis ne sont en rien liés à Trocador.<br />L’utilisation de ce widget se fait en accord avec les CGU de Trocador et tout soucis sera à traiter avec eux directement.</i></p>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    );
  }

  return (
    <>
    <Meta title={pageTitle}/>

    <Container className="mt-4">
   
        <Row className="mb-4 ">
            <Col lg={12}>
                <h1 className="text-center my-4 page-title">Trocador</h1>
            </Col>        
        </Row>

        <Row className="text-center justify-content-md-center">
            <Col lg={12}>
                <p>Trocador est un agr&eacute;gateur d&rsquo;exchange permission-less (sans inscription, ni KYC) qui permet de comparer les frais de passage d&rsquo;une crypto (blockchain A) vers une autre crypto (blockchain B). Il ne s&rsquo;agit pas d&rsquo;un bridge mais bien d&rsquo;un exchange (aucune autorisation/aucun revoke).</p>
                <div className="trocador-widget-link"><em><a href="https://trocador.app/fr/?ref=HkqvOhpjbM" target="_blank">Pour une expérience optimale, visitez directement le site web de Trocador en cliquant ici !</a></em></div>
                <iframe title="Trocador" src="https://trocador.app/step1/?ref=HkqvOhpjbM" width="100%" height="640" scrolling="no"></iframe>
                <h2>.: MarmoTuto :.</h2>
                <Tuto />
            </Col>

        </Row>
            
  </Container>

      
    </>
  )
}

export default Trocador