import { useState } from "react";
import { Row, Col, Form, Button, InputGroup } from "react-bootstrap";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFire } from '@fortawesome/free-solid-svg-icons'

import toast from 'react-hot-toast';

import { validate } from 'bitcoin-address-validation';

function BurnForm({ isBurnLaunched, setIsBurnLaunched, setBurnBitcoinAddress, nft_family_id, setBurnNFTFamilyID, sats, setBurnSats }) {
  const [localBitcoinAddress, setLocalBitcoinAddress] = useState('');

  const handleSubmit = (event) => {
    event.preventDefault();
    const isValidBTCAddress = validate(localBitcoinAddress);
    if (isValidBTCAddress === true) {
      
      setBurnNFTFamilyID(nft_family_id)
      setBurnBitcoinAddress(localBitcoinAddress)
      setBurnSats(sats)
      setIsBurnLaunched(true);

    } else { toast.error('Votre adresse BTC ne semble pas valide !'); }
  }

  return (<Form onSubmit={handleSubmit} className="m-4">
    <Row className="align-items-center text-center">
    <Col lg={12} className="mb-4">      
      <InputGroup>
        <InputGroup.Text>Adresse BTC : </InputGroup.Text>
        <Form.Control type="text" placeholder="Saisir adresse BTC" value={localBitcoinAddress} onChange={e => setLocalBitcoinAddress(e.target.value)} />
      </InputGroup>      
    </Col>
    <Col lg={12}><Button type="submit" disabled={isBurnLaunched} className='btn btn-dark btn-lg'><FontAwesomeIcon icon={faFire} color="brown" className="me-2" size="lg" fade />Burn 1 exemplaire</Button></Col>
    </Row>
</Form>)
}

export default BurnForm