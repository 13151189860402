import { Container, Row, Col } from "react-bootstrap";

import Meta from '../components/Meta'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLink } from '@fortawesome/free-solid-svg-icons'
import { faDiscord, faTwitter } from '@fortawesome/free-brands-svg-icons'

import DocMarmott from "../assets/team/doc-marmott.jpg";
import Nekr from "../assets/team/nekr.jpg";
import RpGmAx from "../assets/team/rpgmax.jpg";
import From0To10K from "../assets/team/from0to10k.jpg";
import TheCryptomath from "../assets/team/thecryptomath.jpg";


import { MouseParallaxContainer, MouseParallaxChild } from "react-parallax-mouse";

const Team = () => {

  const pageTitle = 'Marmottoshis - L\'équipe'

  return (
    <>
    <Meta title={pageTitle}/>

    <Container className="mt-4">
   
        <Row className="mb-4 ">
            <Col lg={12}>
                <h1 className="text-center my-4 page-title">L'équipe</h1>
            </Col>        
        </Row>

        <Row className="text-center justify-content-md-center">
            <Col lg={3} md={12} className="mb-5">
                <div className="team-member">
                    <div className="bg-white rounded shadow-sm py-5 px-4"><img src={DocMarmott} width="100" className="img-fluid rounded-circle mb-3 member-pfp shadow-sm" />
                        <h5 className="mb-0 text-black">Doc Marmott</h5><span className="member-role text-uppercase text-muted">Fondateur</span>
                        <ul className="mb-0 list-inline mt-3">
                            <li className="list-inline-item"><a href="https://linktr.ee/docmarmott" target="_blank" className="member-link"><FontAwesomeIcon icon={faLink} /></a></li>
                            <li className="list-inline-item"><a href="https://twitter.com/DocMarmott" target="_blank" className="member-link"><FontAwesomeIcon icon={faTwitter} /></a></li>
                        </ul>
                    </div>
                </div>
            </Col>

            <Col lg={3} md={12} className="mb-5">
                <div className="team-member">
                    <div className="bg-white rounded shadow-sm py-5 px-4"><img src={Nekr} width="100" className="img-fluid rounded-circle mb-3 member-pfp shadow-sm" />
                        <h5 className="mb-0 text-black">0xNekr</h5><span className="member-role text-uppercase text-muted">Dev. Smart Contract</span>
                        <ul className="social mb-0 list-inline mt-3">
                            <li className="list-inline-item"><a href="https://linktr.ee/nekr_" target="_blank" className="member-link"><FontAwesomeIcon icon={faLink} /></a></li>
                            <li className="list-inline-item"><a href="https://twitter.com/0xNekr" target="_blank" className="member-link"><FontAwesomeIcon icon={faTwitter} /></a></li>
                        </ul>
                    </div>
                </div>
            </Col>

            <Col lg={3} md={12} className="mb-5">
                <div className="team-member">
                    <div className="bg-white rounded shadow-sm py-5 px-4"><img src={RpGmAx} width="100" className="img-fluid rounded-circle mb-3 member-pfp shadow-sm" />
                        <h5 className="mb-0 text-black">RpGmAx</h5><span className="member-role text-uppercase text-muted">Dev. Smart Contract & DApp</span>
                        <ul className="social mb-0 list-inline mt-3">
                            <li className="list-inline-item"><a href="https://profile.rpgmax.fr" target="_blank" className="member-link"><FontAwesomeIcon icon={faLink} /></a></li>
                            <li className="list-inline-item"><a href="https://twitter.com/RpGmAx" target="_blank" className="member-link"><FontAwesomeIcon icon={faTwitter} /></a></li>
                        </ul>
                    </div>
                </div>
            </Col>

            </Row>
            <Row className="text-center justify-content-md-center">

            <Col xl={3} sm={6} className="mb-5">
                <div className="team-member">
                    <div className="bg-white rounded shadow-sm py-5 px-4"><img src={From0To10K} width="100" className="img-fluid rounded-circle mb-3 member-pfp shadow-sm" />
                        <h5 className="mb-0 text-black">From 0 To 10K</h5><span className="member-role text-uppercase text-muted">Scribe</span>
                        <ul className="social mb-0 list-inline mt-3">
                            <li className="list-inline-item"><a href="https://aucoindubloc.com" target="_blank" className="member-link"><FontAwesomeIcon icon={faLink} /></a></li>
                            <li className="list-inline-item"><a href="https://twitter.com/From0To10k" target="_blank" className="member-link"><FontAwesomeIcon icon={faTwitter} /></a></li>
                        </ul>
                    </div>
                </div>
            </Col>

            <Col xl={3} sm={6} className="mb-5">
                <div className="team-member">
                    <div className="bg-white rounded shadow-sm py-5 px-4"><img src={TheCryptomath} width="100" className="img-fluid rounded-circle mb-3 member-pfp shadow-sm" />
                        <h5 className="mb-0 text-black">TheCryptomath</h5><span className="member-role text-uppercase text-muted">Senior Consultant</span>
                        <ul className="social mb-0 list-inline mt-3">
                            <li className="list-inline-item"><a href="https://t.me/TheCryptomath" target="_blank" className="member-link"><FontAwesomeIcon icon={faLink} /></a></li>
                            <li className="list-inline-item"><a href="https://twitter.com/TheCryptomath" target="_blank" className="member-link"><FontAwesomeIcon icon={faTwitter} /></a></li>
                        </ul>
                    </div>
                </div>
            </Col>

        </Row>
        
  </Container>

      
    </>
  )
}

export default Team